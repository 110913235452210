<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-0">
        Without doing any calculations, determine which of the following reactions would have a
        lower
        <stemble-latex content="$\Delta\text{G}$" />
        (i.e. less positive or more negative) if the temperature of the reaction was increased.
        Check all that apply.
      </p>

      <div v-for="option in options" :key="option.value">
        <v-checkbox
          v-model="inputs.input1"
          class="d-inline-block pl-10"
          :value="option.value"
          :dense="true"
          hide-details="true"
          :ripple="false"
          :label="option.text"
        >
          <template #label>
            <stemble-latex :content="`$\\ce{${option.text}}$`" />
          </template>
        </v-checkbox>
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question303',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: [],
      },
      options: [
        {text: 'N2(g) + 3H2(g) -> 2NH3(g)', value: 'a'},
        {text: 'HCl(g) + NH3(g) -> NH4Cl(s)', value: 'b'},
        {text: '(NH4)2Cr2O7(s) -> Cr2O3(s) + 4H2O(g) + N2(g)', value: 'c'},
        {text: '2Fe(s) + 3/2 O2(g) -> Fe2O3(s)', value: 'd'},
      ],
    };
  },
};
</script>
